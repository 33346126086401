// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
  width: 100%;
  height: 3px;
  background-color:transparent;
  position: fixed; /* Ensure the bar stays fixed at the top */
  
  z-index: 999;
/* Smooth transition */
}
  
.progress-bar {
  height: 100%;
  background-color: cyan;
  width: 60%;
  transition: width 0.2s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/common/progess.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,4BAA4B;EAC5B,eAAe,EAAE,0CAA0C;;EAE3D,YAAY;AACd,sBAAsB;AACtB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,UAAU;EACV,kCAAkC;AACpC","sourcesContent":[".progress-container {\n  width: 100%;\n  height: 3px;\n  background-color:transparent;\n  position: fixed; /* Ensure the bar stays fixed at the top */\n  \n  z-index: 999;\n/* Smooth transition */\n}\n  \n.progress-bar {\n  height: 100%;\n  background-color: cyan;\n  width: 60%;\n  transition: width 0.2s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
